import React from "react";
import Link from "../misc/link";

const IntegrationDocumentation = ({ integration }) => {
    return (
        <section className="st-section">
            <div className="st-feature--thin">
                <h2 className="st-heading--2">Learn more about the {integration.name} integration</h2>
                <p className="st-subheading"></p>
                {integration.documentation_link ? (
                    <div className="sample">
                        <h3 className="st-heading--4">Documentation</h3>
                        <p>Detailed documentation on how to start syncing {integration.name} data.</p>
                        <p>
                            <Link
                                to={`/docs${integration.setup_documentation_link}`}
                                className={`st-button-plain--alt`}
                            >
                                {integration.name} Documentation<span className="arrow"></span>
                            </Link>
                        </p>
                    </div>
                ) : (
                    <div className="sample">
                        <h3 className="st-heading--4">Community Support</h3>
                        <p>
                            The Stitch {integration.name} integration is maintained by the open source Singer community.
                        </p>
                        <p>
                            <Link to={integration.gitHubRepository} className={`st-button-plain--alt`}>
                                View the Repo<span className="arrow"></span>
                            </Link>
                        </p>
                    </div>
                )}
            </div>
        </section>
    );
};

export default IntegrationDocumentation;
