import React from "react";
import DatabaseAsset from "../../assets/images/illustrations/asset-integrations-configure.svg";
import MongoDatabaseAsset from "../../assets/images/illustrations/asset-integrations-configure-mongo.svg";
import SectionSeparator from "../layout/section-separator";

const IntegrationFeaturesSection = ({ integration }) => {
    return (
        <>
            {integration.integration_category.name === "Databases" && (
                <>
                    <section className="st-section">
                        {integration.name !== "MongoDB" ? (
                            <div className="st-feature">
                                <h2 className="st-heading--2">Configure your {integration.name} ETL</h2>
                                <p className="st-subheading">
                                    Stitch allows you to configure your ETL process to balance data freshness with cost
                                    and load on your {integration.name} instances.
                                </p>
                                <div className="accompanying-image">
                                    <img src={DatabaseAsset} alt="databases" />
                                </div>
                                <div className="image-features">
                                    <h3 className="st-heading--4">Replication frequency</h3>
                                    <p>
                                        Configure your load type–full or incremental–and how often you want jobs to run,
                                        from every 24 hours down to every minute.
                                    </p>

                                    <h3 className="st-heading--4">Data selection</h3>
                                    <p>
                                        Configure exactly what data gets replicated by selecting the tables and fields
                                        you want to replicate to your warehouse.
                                    </p>
                                </div>
                            </div>
                        ) : (
                            <div className="st-feature">
                                <h2 className="st-heading--2">Configure your {integration.name} ETL</h2>
                                <p className="st-subheading">
                                    Stitch allows you to configure your ETL process to balance data freshness with cost
                                    and load on your {integration.name} instances.
                                </p>

                                <div className="accompanying-image">
                                    <img src={MongoDatabaseAsset} alt="databases" />
                                </div>
                                <div className="image-features">
                                    <h3 className="st-heading--4">Replication frequency</h3>
                                    <p>
                                        Configure how often you want jobs to run, from every 24 hours down to every
                                        minute.
                                    </p>

                                    <h3 className="st-heading--4">Transparency</h3>
                                    <p>
                                        Track the recency and frequency of new records as they flow into your
                                        destination.
                                    </p>
                                </div>
                            </div>
                        )}
                    </section>
                    <SectionSeparator />
                </>
            )}
        </>
    );
};

export default IntegrationFeaturesSection;
